<template>
  <v-card>
    <CollectionHeader @openToAdd="openDialog" @search="changeSearch"></CollectionHeader>
    <DatatableCollections :collections="collections" :loading="loading" :search="search" @edit="openEdit" @remove="openRemove"></DatatableCollections>
    <WDialog
      width="500"
      :dialog="dialog"
      :closeDialog="closeDialog"
      :title="titleDialog"
      :subtitle="subtitleDialog"
    >
      <template v-slot:form-dialog>
        <FormCollection
          v-if="dialog"
          :loading="loading"
          :collectionEdited="collectionEdited"
          @beforeUpdate="updateCollection"
          @beforeCreate="createCollection"
        ></FormCollection>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import DatatableCollections from "./DatatableCollections.vue";
import FormCollection from "@/components/forms/FormCollection.vue";
import CollectionHeader from "./CollectionsHeader.vue";
import WDialog from "@/components/dialogs/WDialog.vue";
import useCollections from "@/composables/useCollections";

export default {
  components: {
    DatatableCollections,
    FormCollection,
    CollectionHeader,
    WDialog,
  },
  setup() {
    // Composition API
    const {
      dialog,
      collectionEdited,
      collections,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createCollection,
      updateCollection,
    } = useCollections();

    return {
      dialog,
      collections,
      search,
      loading,
      collectionEdited,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createCollection,
      updateCollection,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
