<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formCollection" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="3">
            <label for="title">Nombre de la colleccion</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="title"
              v-model="collection.title"
              outlined
              dense
              placeholder="Coleccion"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <label for="description">Descripción</label>
          </v-col>

          <v-col cols="12" class="pb-0">
            <v-textarea
              id="description"
              v-model="collection.description"
              outlined
              dense
              placeholder="Descripción de la collección"
              rows="4"
              :rules="required"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="3">
            <label for="buy_url">URL</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="buy_url"
              v-model="collection.buy_url"
              outlined
              dense
              placeholder="URL de la tienda"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="icon">Imagen de portada</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="icon"
              v-model="image"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileChange"
            ></v-file-input>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    FormTemplate,
  },
  props: {
    collectionEdited: Object,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      image: null,
      collection: this.collectionEdited
        ? { ...this.collectionEdited }
        : {
            text: '',
            urlText: '',
            description: '',
          },
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formCollection.validate()
    },
    reset() {
      this.collection.icon = null
      this.collection.text = ''
      this.collection.urlText = ''
      this.collection.description = ''
      this.$refs.formCollection.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      if (this.collectionEdited) {
        let DTO = { ...this.collection }

        if (this.image) {
          DTO = {
            ...DTO,
            file_collection: this.image,
          }
        }
        // // console.log('actualizar', DTO)
        this.$emit('beforeUpdate', DTO)
      } else {
        // const DTO = { ...this.collection, file_collection: this.image }
        // // console.log('crear', DTO)
        this.$emit('beforeCreate', DTO)
      }
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-service {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #00aca0;
  padding: 20px;
}

.icon-service img {
  max-width: 80px;
  max-height: 80px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
